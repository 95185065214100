nav svg {
  height: 2.5rem;
}

header {
  ::before,
  ::after {
    box-sizing: border-box;
  }

  .navbar-brand {
    svg path {
      fill: $secondary;
    }
  }

  .topbar {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
  }

  .language-dropdown {
    list-style: none;
    padding-bottom: 1rem;

    @include media-breakpoint-up(lg) {
      padding-right: 1rem;
      padding-bottom: 0;
    }

    button {
      border: 1px solid $gray;

      &:focus {
        box-shadow: 0 0 0 .25rem rgba($green, .65);
      }
    }

    img {
      width: 2rem;
    }

    ul {
      padding: 0;

      li {
        a {
          padding: .5rem;
        }
      }

      li:not(:first-child) {
        border-top: 1px solid $gray;
      }
    }

    span {
      &.language-name {
        @include media-breakpoint-only(lg) {
          display: none;
        }
      }
    }
  }

  .populihub-nav-item {
    font-family: $font-family-sans-serif;
    font-size: $description-font-size;

    @include media-breakpoint-only(lg) {
      font-size: .9rem;
    }

    @include media-breakpoint-up(xl) {
      padding: 0 .7rem;
    }

    a {
      &.active {
        border-bottom: 2px solid $blue;

        &:active,
        &:focus,
        &:hover {
          border-bottom: 0;
        }
      }

      &.nav-link {
        font-family: $headings-font-family;
        font-weight: bold;
        border-bottom: 2px solid transparent;

        &:active,
        &:focus,
        &:hover {
          border-bottom: 2px solid $blue;
        }
      }
    }
  }

  &.scrolled {
    background-color: $secondary;

    .nav-link {
      &:active,
      &:focus,
      &:hover {
        border-bottom: 2px solid $white;
      }
    }

    svg path {
      fill: $white;
    }

    button {
      color: $white;
    }

    .navbar-toggler-icon {
      color: $white;
    }

    .language-dropdown {
      a {
        color: $blue;
      }

      button {
        color: $white;

        &:focus {
          box-shadow: 0 0 0 .25rem rgba($gray, .65);
        }
      }
    }
  }

  a {
    &.button-login {
      @include media-breakpoint-down(lg) {
        margin: 1rem 1rem 1rem 0;
      }
    }
  }
}
