
.step-wrapper {
  font-family: $headings-font-family;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;

  button {
    background-color: $gray;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 8rem;
    height: 8rem;
    border-radius: 5%;
    border: 1px solid $gray;

    &.active {
      border: 1px solid $primary;
      background-color: $primary;

      .step-description {
        color: $white;
      }
    }

    .step-number {
      background-color: $white;
      border-radius: 50%;
      padding-top: .9rem;
      width: 3rem;
      height: 3rem;
    }

    .step-description {
      margin-top: 1rem;
    }
  }
}
