body {
  min-height: 100vh;
  background-color: $blue;
  color: $secondary;
}

header {
  background-color: $gray-white;
  padding: .2rem 0;
}

main {
  background-color: $white;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  min-height: 66vh;
  font-family: $font-family-sans-serif;

  a {
    color: $green-dark;
  }
}

footer {
  padding: 1rem 0;
}

.announcement {
  background-color: antiquewhite;
  margin: 1rem;
}
