@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat-bold-webfont.eot");
  src:
    url("../fonts/montserrat-bold-webfont.eot?#iefix") format("embedded-opentype"),
    url("../fonts/montserrat-bold-webfont.woff2") format("woff2"),
    url("../fonts/montserrat-bold-webfont.woff") format("woff"),
    url("../fonts/montserrat-bold-webfont.ttf") format("truetype"),
    url("../fonts/montserrat-bold-webfont.svg#montserratbold") format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat-bolditalic-webfont.eot");
  src:
    url("../fonts/montserrat-bolditalic-webfont.eot?#iefix") format("embedded-opentype"),
    url("../fonts/montserrat-bolditalic-webfont.woff2") format("woff2"),
    url("../fonts/montserrat-bolditalic-webfont.woff") format("woff"),
    url("../fonts/montserrat-bolditalic-webfont.ttf") format("truetype"),
    url("../fonts/montserrat-bolditalic-webfont.svg#montserratbold_italic") format("svg");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Vollkorn";
  src: url("../fonts/vollkorn-regular-webfont.eot");
  src:
    url("../fonts/vollkorn-regular-webfont.eot?#iefix") format("embedded-opentype"),
    url("../fonts/vollkorn-regular-webfont.woff2") format("woff2"),
    url("../fonts/vollkorn-regular-webfont.woff") format("woff"),
    url("../fonts/vollkorn-regular-webfont.ttf") format("truetype"),
    url("../fonts/vollkorn-regular-webfont.svg#vollkornregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Vollkorn";
  src: url("../fonts/vollkorn-italic-webfont.eot");
  src:
    url("../fonts/vollkorn-italic-webfont.eot?#iefix") format("embedded-opentype"),
    url("../fonts/vollkorn-italic-webfont.woff2") format("woff2"),
    url("../fonts/vollkorn-italic-webfont.woff") format("woff"),
    url("../fonts/vollkorn-italic-webfont.ttf") format("truetype"),
    url("../fonts/vollkorn-italic-webfont.svg#vollkornitalic") format("svg");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Vollkorn";
  src: url("../fonts/vollkorn-bold-webfont.eot");
  src:
    url("../fonts/vollkorn-bold-webfont.eot?#iefix") format("embedded-opentype"),
    url("../fonts/vollkorn-bold-webfont.woff2") format("woff2"),
    url("../fonts/vollkorn-bold-webfont.woff") format("woff"),
    url("../fonts/vollkorn-bold-webfont.ttf") format("truetype"),
    url("../fonts/vollkorn-bold-webfont.svg#vollkornbold") format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Vollkorn";
  src: url("../fonts/vollkorn-bolditalic-webfont.eot");
  src:
    url("../fonts/vollkorn-bolditalic-webfont.eot?#iefix") format("embedded-opentype"),
    url("../fonts/vollkorn-bolditalic-webfont.woff2") format("woff2"),
    url("../fonts/vollkorn-bolditalic-webfont.woff") format("woff"),
    url("../fonts/vollkorn-bolditalic-webfont.ttf") format("truetype"),
    url("../fonts/vollkorn-bolditalic-webfont.svg#vollkornbold_italic") format("svg");
  font-weight: bold;
  font-style: italic;
}
