.subtitle {
  font-size: 1.5rem;
  font-weight: 400;
  margin: 1rem 0;
}

.sub_features-caption {
  font-size: 1.2rem;
  font-weight: 500;
}
