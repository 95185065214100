.modal {
  color: $secondary;

  .technical-container {
    .tier-title {
      font-family: $headings-font-family;
      font-size: 1.5rem;
      padding-bottom: 1rem;
    }

    .list-group-item {
      color: $secondary;
    }
  }
}
