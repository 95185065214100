.login-page {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  text-align: center;

  h1 {
    margin: 1rem;
  }
}

.sign-in {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
}

.register-suggestion {
  margin: 1rem 0;
}

.register__separator {
  display: block;
  text-align: center;
  position: relative;
  z-index: 1;
  font-style: italic;
  margin: 1rem 0 2rem;

  &::before {
    content: "";
    display: block;
    height: 1px;
    width: 100%;
    background-color: gray;
    position: absolute;
    top: 50%;
    z-index: -1;
  }
}

.register__separator__text {
  display: inline-block;
  background-color: $white;
  padding: 0 1rem;
}
