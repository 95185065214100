footer {
  color: $white;

  a,
  .btn-link {
    color: $white;
  }

  .logo-populihub {
    align-self: flex-end;
    width: 250px;
    margin-bottom: 2rem;

    @include media-breakpoint-up(lg) {
      width: 100%;
      margin-bottom: 0;
    }

    svg path {
      fill: $white;
    }
  }

  .mainio-logo-container {
    display: flex;
    align-items: flex-start;
    flex-flow: column wrap;

    .text {
      font-family: $headings-font-family;
      text-transform: uppercase;
      padding-right: 1rem;
      font-weight: bold;
    }

    .logo-mainio {
      img {
        height: 60px;

        @include media-breakpoint-up(md) {
          height: 50px;
        }
      }
    }
  }

  .contact-and-copyright {
    align-self: flex-end;
  }

  .terms-and-conditions {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin: 2rem 0;

    @include media-breakpoint-up(md) {
      justify-content: flex-end;
      margin: 0;
    }
  }

  .decidim-logo-text {
    line-height: 2.9;
    font-size: .8rem;
    margin-right: .5rem;

    @include media-breakpoint-up(md) {
      line-height: 1;
    }
  }

  .logo-decidim {
    img {
      height: 30px;
    }
  }
}
