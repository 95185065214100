$card-font-bold: 600;
$card-header-background: $gray-light;
$card-gray: #6a728a;

a.card {
  color: $body-color;
  text-decoration: none;
  transition: box-shadow .3s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    box-shadow: 0 10px 30px -10px rgba(0, 0, 0, .75);

    .card-title,
    .card-link {
      color: $link-color;
    }
  }
}

.card-subchapter {
  a.card {
    border: none;
  }
}

.tier-card {
  border-radius: .25rem;
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .1);
  height: 100%;
  margin: 0 1rem;
  max-width: 280px;

  @include media-breakpoint-up(lg) {
    max-width: none;
  }

  ::before,
  ::after {
    box-sizing: border-box;
  }

  &.with-border {
    border: 2px solid $purple;
    border-top: 0;
    border-radius: 0 0 2px 2px;
    box-shadow: .5rem 1rem 1rem $gray-dark;
  }

  .card-header {
    background: $card-header-background;
    text-align: center;
    border: 0;

    .card-subtitle {
      color: $card-gray;
      min-height: 3rem;
    }

    .price-row {
      display: flex;
      justify-content: center;
      flex-wrap: nowrap;

      .currency {
        color: $card-gray;
        font-size: 1.2rem;
      }

      .number {
        font-size: 2rem;
        padding: 0 .5rem;
      }

      .frequency {
        display: flex;
        align-self: end;
        white-space: nowrap;
        padding-bottom: .5rem;
        color: $card-gray;
      }
    }
  }

  .card-content {
    background: $card-header-background;
    text-align: center;
    padding: .5rem;

    a {
      color: $white;
    }
  }

  .card-footer {
    background-color: $white;
    padding: 2rem;
    border: 0;
  }

  .card-description {
    border-bottom: 1px solid $card-gray;

    .description-line {
      color: $card-gray;
      border-top: 1px solid $card-gray;
      padding: .5rem;
    }
  }

  .technical-details-link {
    margin-top: 1rem;
  }

  .technical_details {
    display: none;
  }
}

.contact-card {
  text-align: center;
  margin: 0 1rem 1rem;
  width: 280px;
  border-radius: .25rem;
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .1);
  padding: 0;

  @include media-breakpoint-up(md) {
    width: 50vw;
  }

  .card-header {
    background-color: $card-header-background;
    font-size: 1.8rem;
    font-weight: $card-font-bold;
    min-height: 7vh;
    border: 0;
  }

  .card-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 7vh;
    padding: 1rem;
    border: 0;

    a {
      color: $white;
    }
  }
}

.card-subchapter {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;

  svg {
    width: 3rem !important;
    height: 3rem !important;
    color: #418b78;
  }

  .fa,
  .fas {
    font-size: 3rem;
    color: #418b78;
  }

  .subchapter-header {
    padding: 1rem 0;
    font-family: $headings-font-family;
    font-size: 1.4rem;
    text-align: center;
  }

  .subchapter-content {
    text-align: center;
    color: $gray-dark;
  }
}
