.pricing-title {
  margin-bottom: 2rem;
}

.tier-column {
  display: flex;
}

.tier-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

.tier-preheader {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 3rem;

  &.with-border {
    background-image: radial-gradient(circle, #3d8bd3 0, #7b86ff 48%, #8d7bff 85%, #7284e8 98%) !important;
    color: $white;
    margin: 0 1rem;
    border: 1px solid $purple;
    border-radius: 5px 5px 0 0;
  }
}

.choose-tier {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
}

.contact {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.front-benefits {
  .fas {
    font-size: 40px;
  }
}
