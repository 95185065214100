.py-section {
  padding: 4rem 0;
}

.decorator {
  position: relative;

  &::after {
    content: "";
    display: block;
    background: $secondary;
    width: 50px;
    height: 3px;
    margin: -.5rem auto 2rem;
  }
}

.img-cover {
  width: 100%;
  object-fit: cover;

  @include media-breakpoint-up(md) {
    width: auto;
    height: 100%;
  }
}
