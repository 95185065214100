// Use this file to customize any Bootstrap SCSS variables + add your own.
// See: https://github.com/twbs/bootstrap/blob/main/scss/_variables.scss

// Bootstrap defautls
$variable-prefix: bs-;

// Bigger large buttons
$btn-padding-x-lg: 1.3rem;
$btn-padding-y-lg: .8rem;

// Font sizes
$h1-font-size: 3rem;
$h2-font-size: 1.7rem;
$h3-font-size: 1.5rem;
$subheading-font-size: 1.5rem;
$description-font-size: 1.15rem;

// Colors
$red: #fc6666;
$green: #418b78;
$green-dark: #3c806e;
$blue: #183b56;
$light-blue: #e4f3ff;
$yellow: #ffbf63;
$gray: #ccc;
$gray-light: #eff0e96b;
$gray-dark: #424767;
$gray-white: #f0f1ea;
$white: #fff;

// Navbar
$navbar-light-color: rgba($blue, .65);
$navbar-light-active-color: $blue;
$navbar-light-hover-color: $blue;
$navbar-dark-color: rgba($white, .65);
$navbar-dark-active-color: $white;
$navbar-dark-hover-color: $white;

// Theme colors
$primary: $green;
$secondary: $blue;
$tertiary: $red;
$purpe: #7284e8;

// Fonts
$font-family-sans-serif: sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-serif: Vollkorn, serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base: var(--#{$variable-prefix}font-serif);
$font-family-headings: var(--#{$variable-prefix}font-sans-serif);
$font-family-code: var(--#{$variable-prefix}font-monospace);

$font-size-root: 18px;

$headings-font-family: $font-family-headings;
$headings-font-weight: bold;
