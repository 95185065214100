.chapter {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 1rem 0;

  img {
    width: 100%;
  }

  .chapter-title {
    background-color: transparent;
    font-family: $headings-font-family;
    font-size: 1.5rem;
    border: 0;
    line-height: 1.3;
  }

  .chapter-subtitle {
    font-family: $headings-font-family;
    font-size: $description-font-size;
  }

  .chapter-content {
    padding: 1rem 0;
  }
}

.description {
  font-size: $description-font-size;
  color: $secondary;
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}
