.btn-primary {
  &:active,
  &:focus,
  &:hover {
    color: $white;
    border: 1px solid rgba($primary, .65);
    box-shadow: 0 0 0 .25rem rgba($primary, .65);
  }
}

.button-populihub {
  color: $white;
}

.button-alternative {
  background-color: $secondary;
  color: $white;
  border: 1px solid $secondary;

  &:active,
  &:focus,
  &:hover {
    background-color: #376588;
    color: $white;
    border: 1px solid rgba($blue, .65);
    box-shadow: 0 0 0 .25rem rgba($blue, .65);
  }
}

.action-buttons {
  .button-populihub {
    margin: 1rem 1rem 0 0;
  }

  .button-alternative {
    margin: 1rem 1rem 0 0;
  }
}

.button-container {
  display: flex;
  justify-content: center;

  button {
    color: $white;
  }
}

.button-login {
  @include media-breakpoint-up(lg) {
    margin-right: 1rem;
  }
}
