.figure-holder {
  height: 100%;
  min-height: 200px;
  position: relative;
  background-size: cover;

  &.bg-black {
    background-color: #000;
  }
}

a.more-link {
  text-decoration: none;
  border-bottom: none;
  color: $black;

  &:hover,
  &:focus,
  &:active {
    color: $gray-dark;
  }
}
